import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type {
	Category,
	Textile,
	PlatformCatalogProduct,
	CollectionShowroom,
	CollectionsItemsSummary,
} from "@/types/products";
import type { Order } from "@/types/filters";

export const usePM = () => {
	const {
		viewProduct,
		listCategoriesByPlatformTextilesApplication,
		listCollectionsItemsSummaryShowroom,
		viewCollectionShowroom,
		viewTextileShowroom,
		viewProductsShowroom,
	} = usePMApi();

	const { $sentry } = useNuxtApp();

	const emptyPagination: Pagination = {
		page: 0,
		limit: 0,
		total: 0,
		order: "",
		pages_count: 0,
		next: null,
		prev: null,
	};

	const listProductsForPlatform = async (
		platformId: string,
		page: number = 1,
		limit: number = 21,
		order: Order = {}
	): Promise<{ platformCatalogProducts: PlatformCatalogProduct[]; pagination: Pagination }> => {
		let platformCatalogProducts: PlatformCatalogProduct[] = [];
		let pagination: Pagination = emptyPagination;

		try {
			const { response, error } = await viewProductsShowroom<{
				platform_catalog_products: PlatformCatalogProduct[];
				pagination: Pagination;
				total_in_stock_inventory: number;
			}>(
				platformId,
				{
					limit,
					page,
					order,
				},
				{ requestFunc: "$fetch" }
			);

			if (error.value) throw error.value;

			if (response.value?.data) {
				platformCatalogProducts = response.value.data.platform_catalog_products || [];
				pagination = response.value.data.pagination;
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch platform catalog (${err.data?.error.message})`);

			return { platformCatalogProducts: [], pagination: emptyPagination };
		}
		return { platformCatalogProducts, pagination };
	};

	const viewProductById = async (product_id: string) => {
		const res = await viewProduct(product_id);
		if (res.error.value) return undefined;
		const product = res.response.value?.data;
		return product;
	};

	const listCategoriesForShowroom = async (org_id: string) => {
		const res = await listCategoriesByPlatformTextilesApplication<Category[]>({
			organization_id: org_id,
			platform_model: "Showroom",
		});
		if (res.error.value) return [];
		const categories = res.response.value.data;
		return categories;
	};

	const listCollections = async (org_id: string) => {
		try {
			const { response, error } = await listCollectionsItemsSummaryShowroom<CollectionsItemsSummary>(
				org_id,
				undefined,
				{ requestFunc: "$fetch" }
			);

			if (error.value) throw error.value;

			if (response.value?.data?.results) {
				const collections = response.value?.data?.results;

				if (Array.isArray(collections)) {
					return collections.filter(c => c.visibility === true && c.collections_textile_items?.length);
				}
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch collections summary (${err.data?.error.message})`);
		}

		return [];
	};

	const viewCollection = async (identifier: string) => {
		// takes either id or slug as an identifier
		try {
			const { response, error } = await viewCollectionShowroom<CollectionShowroom>(
				identifier,
				{},
				{ requestFunc: "$fetch" }
			);

			if (error.value) throw error.value;

			if (response.value?.data) {
				return response.value.data;
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch showroom collection (${err.data?.error.message})`);
		}
	};

	const viewTextileById = async (textile_id: string) => {
		try {
			const { response, error } = await viewTextileShowroom<Textile>(textile_id);

			if (error.value) throw error.value;

			return response.value?.data;
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch textile (${err.data?.error.message})`);

			return undefined;
		}
	};

	return {
		viewProductById,
		listCategoriesForShowroom,
		listCollections,
		viewCollection,
		viewTextileById,
		listProductsForPlatform,
	};
};
